import React, { useEffect, useRef, useState } from "react";

interface ChartProps {
  data: number[];
  colors: string[];
  lineThickness: number;
  backgroundLinesType: string;
  parentWidth?: number | undefined;
  fixedChartWidth?: number;
  fixedChartHeight?: number;
  fixedMarginLeft: number;
  fixedMarginTop: number;
  fixedMarginBottom: number;
  fixedMarginRight: number;
  backgroundColor: string;
  backgroundLineColor: string;
  yAxisFontSize: number;
  xAxisFontSize: number;
}

const LineChart: React.FC<ChartProps> = (props) => {
  const targetRef = useRef<any>(null);

  const [width, setWidth] = useState(10);
  const [height, setHeight] = useState(10);

  // useEffect will run on stageCanvasRef value assignment
  useEffect(() => {
    // The 'current' property contains info of the reference:
    // align, title, ... , width, height, etc.

    const handleResize = () => {
      // Perform actions on window resize
      if (targetRef.current) {
        let newHeight = targetRef.current.offsetHeight;
        let newWidth = targetRef.current.offsetWidth;

        // If not props of fixed height and width -> set the height and width from the resize:
        if (!props.fixedChartHeight) {
          setHeight(newHeight);
        }
        if (!props.fixedChartWidth) {
          setWidth(newWidth);
        }
      }

      // If props of fixed height and width -> set the height and width to the values from the props:
      if (props.fixedChartWidth) {
        setWidth(props.fixedChartWidth);
      }
      if (props.fixedChartHeight) {
        setHeight(props.fixedChartHeight);
      }
    };
    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [
    targetRef,
    props.parentWidth,
    props.fixedChartWidth,
    props.fixedChartHeight,
  ]);

  //const yAxisWidth = 40;
  const innerChartWidth =
    width - 30 - props.fixedMarginLeft - props.fixedMarginRight;

  let allValues = [] as number[];

  for (let i = 0; i < props.data.length; i++) {
    const dataElement = props.data[i] as any;
    const dataKey = Object.keys(dataElement)[0];
    const dataValues = dataElement[dataKey];

    dataValues.forEach((_element: number) => {
      allValues.push(_element);
    });
  }

  // Calculate the maximum value in the data array
  //const maxData = Math.max(...allValues);
  let maxData = -9999999;

  for (let i = 0; i < props.data.length; i++) {
    const dataElement = props.data[i] as any;
    const dataKey = Object.keys(dataElement)[0];
    const dataSubElement = dataElement[dataKey];

    dataSubElement.forEach((_element: any, index: number) => {
      const dataSubElementKeys = Object.keys(_element);

      dataSubElementKeys.forEach((_key, index) => {
        const value = _element[_key];
        if (value > maxData) {
          maxData = value;
        }
      });
    });
  }

  // Add some more than max for spacing
  maxData = maxData * 1.17;

  // Function to map data points to SVG path coordinates
  const renderDataCirclePoints = (data: number[]): JSX.Element => {
    const circles: JSX.Element[] = [];
    const texts: JSX.Element[] = [];

    // First round -> draw circles
    for (let i = 0; i < data.length; i++) {
      const dataElement = props.data[i] as any;
      const dataKey = Object.keys(dataElement)[0];
      const dataSubElement = dataElement[dataKey];

      dataSubElement.forEach((_element: any, indexOuter: number) => {
        const dataSubElementKeys = Object.keys(_element);

        dataSubElementKeys.forEach((_key, index) => {
          const value = _element[_key];

          const xValue =
            (i / (data.length - 1)) * innerChartWidth + props.fixedMarginLeft;
          const yValue =
            props.fixedMarginTop +
            (1 - value / maxData) *
              (height - 30 - props.fixedMarginTop - props.fixedMarginBottom);

          const color =
            props.colors.length > indexOuter
              ? props.colors[indexOuter]
              : "#225588";

          circles.push(
            <g>
              <circle
                cx={xValue}
                cy={yValue}
                fill={color}
                r="4"
                stroke={props.backgroundColor}
                strokeWidth="2"
              />
            </g>
          );

          // For now only small text on last element when only copying of code is possible

          if (i == data.length - 1) {
            texts.push(
              <g>
                <circle
                  cx={xValue}
                  cy={yValue}
                  fill="#00000000"
                  r="10"
                  stroke="#eee00000"
                  strokeWidth="2"
                />

                <polygon
                  className="hidden"
                  fill="#00000099"
                  points={
                    xValue +
                    ", " +
                    (yValue - 5) +
                    " " +
                    (xValue - 5) +
                    ", " +
                    (yValue - 9) +
                    " " +
                    (xValue + 5) +
                    ", " +
                    (yValue - 9)
                  }
                />

                <rect
                  className="hidden"
                  x={xValue - 27}
                  y={yValue - 28.8}
                  width="55"
                  height="20"
                  fill="#00000099"
                  rx="4"
                />

                <text
                  className="hidden"
                  key={i}
                  fontWeight="bold"
                  x={xValue}
                  y={yValue - 15}
                  textAnchor="middle"
                  fontSize="12"
                  fill="#ffffffff"
                >
                  {_key}
                </text>
              </g>
            );
          }

          // Remove texts for now when only copying of code is possible
          /*
          texts.push(
            <g>
              <circle
                cx={xValue}
                cy={yValue}
                fill="#00000000"
                r="10"
                stroke="#eee00000"
                stroke-width="2"
              />

              <polygon
                className="hidden"
                fill="#ffffffdd"
                points={
                  xValue +
                  ", " +
                  (yValue - 5) +
                  " " +
                  (xValue - 5) +
                  ", " +
                  (yValue - 9) +
                  " " +
                  (xValue + 5) +
                  ", " +
                  (yValue - 9)
                }
              />

              <rect
                className="hidden"
                x={xValue - 37}
                y={yValue - 45}
                width="75"
                height="36"
                fill="#ffffffdd"
                rx="4"
              />

              <text
                className="hidden"
                key={i}
                font-weight="bold"
                x={xValue}
                y={yValue - 32}
                textAnchor="middle"
                fontSize="10"
                color="black"
              >
                {_key}
              </text>
              <text
                className="hidden"
                key={i}
                x={xValue}
                y={yValue - 15}
                textAnchor="middle"
                fontSize="10"
                color="black"
              >
                {dataKey}: {value}
              </text>
            </g>
          );
          */
        });
      });
    }

    return (
      <>
        {circles} {texts}
      </>
    );
  };

  // Function to map data points to SVG path coordinates
  const mapDataToPath = (data: number[]): string[] => {
    let pathDatas: any = [];

    let subElementKeys = [] as any[];

    for (let i = 0; i < data.length; i++) {
      const dataElement = props.data[i] as any;
      const dataKey = Object.keys(dataElement)[0];
      const dataSubElement = dataElement[dataKey];

      dataSubElement.forEach((_element: any, index: number) => {
        const dataSubElementKeys = Object.keys(_element);

        dataSubElementKeys.forEach((_key, index) => {
          subElementKeys.push(_key);
          if (pathDatas[_key] === undefined) {
            pathDatas[_key] = [];
          }

          const value = _element[_key];

          pathDatas[_key].push(
            `${
              (i / (data.length - 1)) * innerChartWidth + props.fixedMarginLeft
            } ${
              props.fixedMarginTop +
              (1 - value / maxData) *
                (height - 30 - props.fixedMarginTop - props.fixedMarginBottom)
            }`
          );
        });
      });
    }

    let returnstrings: string[] = [];

    let uniqueSubElementKeys = subElementKeys.filter((element, index) => {
      return subElementKeys.indexOf(element) === index;
    });

    uniqueSubElementKeys.forEach((_key, index) => {
      const datapointsArray = pathDatas[_key];

      returnstrings.push(`M ${datapointsArray.join(" L ")}`);
    });

    return returnstrings;
  };

  // Function to render y-axis lines and labels
  const renderYAxis = (): JSX.Element => {
    const yAxisLines: JSX.Element[] = [];
    const yAxisLabels: JSX.Element[] = [];

    for (let i = 0; i <= 10; i++) {
      const y =
        props.fixedMarginTop +
        (1 - i / 10) *
          (height - 30 - props.fixedMarginTop - props.fixedMarginBottom);
      yAxisLines.push(
        <line
          key={i}
          x1={props.fixedMarginLeft}
          y1={y}
          x2={innerChartWidth + props.fixedMarginLeft}
          y2={y}
          stroke={props.backgroundLineColor}
          strokeWidth={0.5}
          strokeDasharray={props.backgroundLinesType == "dashed" ? "5 5" : "0"}
        />
      );
      yAxisLabels.push(
        <text
          key={i}
          x={props.fixedMarginLeft - 5}
          y={y - 1}
          textAnchor="end"
          dominantBaseline="central"
          fontSize={props.yAxisFontSize}
          color="black"
        >
          {Math.round((maxData * i) / 10)}
        </text>
      );
    }

    return (
      <>
        {yAxisLines}
        {yAxisLabels}
      </>
    );
  };

  // Function to render x-axis lines and labels
  const renderXAxis = (): JSX.Element => {
    const xAxisLines: JSX.Element[] = [];
    const xAxisLabels: JSX.Element[] = [];

    for (let i = 0; i <= props.data.length - 1; i++) {
      const dataElement = props.data[i];
      const dataKey = Object.keys(dataElement)[0];

      console.log(dataKey);

      const xPos =
        (i / (props.data.length - 1)) * innerChartWidth + props.fixedMarginLeft;
      xAxisLines.push(
        <line
          key={i}
          x1={xPos}
          y1={props.fixedMarginTop}
          x2={xPos}
          y2={height - 30 - props.fixedMarginBottom}
          stroke={props.backgroundLineColor}
          strokeWidth={0.5}
          strokeDasharray={props.backgroundLinesType == "dashed" ? "5 5" : "0"}
        />
      );

      xAxisLabels.push(
        <text
          key={i}
          x={xPos}
          y={height - 42 - props.fixedMarginBottom + 20}
          textAnchor="middle"
          fontSize={props.xAxisFontSize}
          dominantBaseline="hanging"
          color="black"
        >
          {dataKey}
        </text>
      );
    }

    return (
      <>
        {xAxisLines}
        {xAxisLabels}
      </>
    );
  };

  return (
    <div
      id="target"
      ref={targetRef}
      style={{
        width: "100%",
        height: "100%",
      }}
    >
      <div
        style={{
          width: "100%",
          height: "100%",
          overflowY: "auto",
          overflowX: "auto",
        }}
      >
        <div
          style={{
            padding: "0px",
            backgroundColor: props.backgroundColor,
            margin: "0 auto",
            width: props.fixedChartWidth
              ? props.fixedChartWidth + "px"
              : "100%",
            height: props.fixedChartHeight
              ? props.fixedChartHeight + "px"
              : "100%",
          }}
        >
          <svg height={height} width={width}>
            {renderYAxis()}
            {renderXAxis()}

            {mapDataToPath(props.data).map((str, index) => (
              <path
                d={str}
                fill="none"
                strokeLinecap="round"
                stroke={
                  props.colors.length > index ? props.colors[index] : "#225588"
                }
                strokeWidth={props.lineThickness}
              />
            ))}

            {renderDataCirclePoints(props.data)}
          </svg>
        </div>
      </div>
    </div>
  );
};

export default LineChart;
