import React, { useEffect, useRef, useState } from "react";

interface ChartProps {
  data: number[];
  colors: string[];
  lineThickness: number;
  backgroundLinesType: string;
  parentWidth?: number | undefined;
  fixedChartWidth?: number;
  fixedChartHeight?: number;
  fixedMarginLeft: number;
  fixedMarginTop: number;
  fixedMarginBottom: number;
  fixedMarginRight: number;
  backgroundColor: string;
  backgroundLineColor: string;
  yAxisFontSize: number;
  xAxisFontSize: number;
}

const BarChart: React.FC<ChartProps> = (props) => {
  const targetRef = useRef<any>(null);

  const [width, setWidth] = useState(10);
  const [height, setHeight] = useState(10);

  const barGroupGap = 6;
  const barGap = 2;

  const legendsWidth = 100;

  const yAxisWidth = props.fixedMarginLeft;

  const innerChartWidth =
    width - props.fixedMarginRight - yAxisWidth - legendsWidth;

  useEffect(() => {
    const handleResize = () => {
      // Perform actions on window resize
      if (targetRef.current) {
        let newHeight = targetRef.current.offsetHeight;
        let newWidth = targetRef.current.offsetWidth;

        setHeight(newHeight);

        // If not props of fixed height and width -> set the height and width from the resize:
        if (!props.fixedChartHeight) {
          setHeight(newHeight);
        }
        if (!props.fixedChartWidth) {
          setWidth(newWidth);
        }
      }

      // If props of fixed height and width -> set the height and width to the values from the props:
      if (props.fixedChartWidth) {
        setWidth(props.fixedChartWidth);
      }
      if (props.fixedChartHeight) {
        setHeight(props.fixedChartHeight);
      }
    };
    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [
    targetRef,
    props.parentWidth,
    props.fixedChartWidth,
    props.fixedChartHeight,
  ]);

  let allValues = [] as number[];

  for (let i = 0; i < props.data.length; i++) {
    const dataElement = props.data[i] as any;
    const dataKey = Object.keys(dataElement)[0];
    const dataValues = dataElement[dataKey];

    dataValues.forEach((_element: number) => {
      allValues.push(_element);
    });
  }

  // Calculate the maximum value in the data array
  //const maxData = Math.max(...allValues);
  let maxData = -9999999;

  for (let i = 0; i < props.data.length; i++) {
    const dataElement = props.data[i] as any;
    const dataKey = Object.keys(dataElement)[0];
    const dataSubElement = dataElement[dataKey];

    dataSubElement.forEach((_element: any, index: number) => {
      const dataSubElementKeys = Object.keys(_element);

      dataSubElementKeys.forEach((_key, index) => {
        const value = _element[_key];
        if (value > maxData) {
          maxData = value;
        }
      });
    });
  }

  // Add some more than max for spacing
  maxData = maxData * 1.17;

  // Function to map data points to SVG path coordinates
  const renderBars = (data: number[]): JSX.Element => {
    const rects: JSX.Element[] = [];
    const texts: JSX.Element[] = [];

    // unique list for putting labels. For same colors for the label in all bars and label text.
    const labelElements = [] as string[];

    const barGroupWidth = (1 / data.length) * innerChartWidth - barGroupGap;

    // First round -> draw circles
    for (let i = 0; i < data.length; i++) {
      const dataElement = props.data[i] as any;
      const dataKey = Object.keys(dataElement)[0];
      const dataSubElement = dataElement[dataKey];

      dataSubElement.forEach((_element: any, indexOuter: number) => {
        const dataSubElementKeys = Object.keys(_element);

        const barWidth = (barGroupWidth + barGroupGap) / dataSubElement.length;

        dataSubElementKeys.forEach((_key, indexInner) => {
          const value = _element[_key];

          // If not in label list then push to label list
          if (labelElements.indexOf(_key) === -1) {
            labelElements.push(_key);
            console.log("le" + labelElements);
          }

          // Color is equal to labelList element index
          const labelElementIndex = labelElements.indexOf(_key);
          const color =
            props.colors.length > labelElementIndex - 1
              ? props.colors[labelElementIndex]
              : "#225588";

          const xValue =
            (i / data.length) * innerChartWidth +
            yAxisWidth +
            indexOuter * barWidth;

          const yValue =
            props.fixedMarginTop +
            (1 - value / maxData) *
              (height - 30 - props.fixedMarginTop - props.fixedMarginBottom);

          rects.push(
            <rect
              className="hidden"
              x={
                xValue + (dataSubElement.length * 2 - indexOuter * barGroupGap)
              }
              y={yValue}
              width={barWidth - barGroupGap - barGap}
              height={height - 30 - yValue - props.fixedMarginBottom}
              fill={color}
              rx="1"
            />
          );

          // Render legends
        });
      });
    }

    const legendXPosition = innerChartWidth + yAxisWidth;

    for (let i = 0; i < labelElements.length; i++) {
      const legendYPosition = 40 + i * 25;

      const color = props.colors.length > i - 1 ? props.colors[i] : "#225588";
      texts.push(
        <g>
          <circle
            cx={legendXPosition + 25}
            cy={legendYPosition - 19}
            fill={color}
            r="4"
          />

          <text
            className="hidden"
            key={"test"}
            font-weight="bold"
            x={legendXPosition + 34}
            y={legendYPosition - 15}
            textAnchor="start"
            fontSize="12"
            fill="#000"
          >
            {labelElements[i]}
          </text>
        </g>
      );
    }

    return (
      <>
        {rects} {texts}
      </>
    );
  };

  // Function to render y-axis lines and labels
  const renderYAxis = (): JSX.Element => {
    const yAxisLines: JSX.Element[] = [];
    const yAxisLabels: JSX.Element[] = [];

    for (let i = 0; i <= 10; i++) {
      const y =
        props.fixedMarginTop +
        (1 - i / 10) *
          (height - 30 - props.fixedMarginTop - props.fixedMarginBottom);
      yAxisLines.push(
        <line
          key={i}
          x1={yAxisWidth}
          y1={y}
          x2={innerChartWidth + yAxisWidth}
          y2={y}
          stroke={props.backgroundLineColor}
          strokeWidth={0.5}
          strokeDasharray={props.backgroundLinesType == "dashed" ? "5 5" : "0"}
        />
      );
      yAxisLabels.push(
        <text
          key={i}
          x={yAxisWidth - 5}
          y={y - 1}
          textAnchor="end"
          dominantBaseline="central"
          fontSize={props.yAxisFontSize}
          color="black"
        >
          {Math.round((maxData * i) / 10)}
        </text>
      );
    }

    return (
      <>
        {yAxisLines}
        {yAxisLabels}
      </>
    );
  };

  // Function to render x-axis lines and labels
  const renderXAxis = (): JSX.Element => {
    const xAxisLines: JSX.Element[] = [];
    const xAxisLabels: JSX.Element[] = [];

    const barGroupWidth =
      (1 / props.data.length) * innerChartWidth - barGroupGap;

    for (let i = 0; i <= props.data.length - 1; i++) {
      const dataElement = props.data[i];
      const dataKey = Object.keys(dataElement)[0];

      console.log(dataKey);

      const xPos =
        (i / props.data.length) * innerChartWidth +
        yAxisWidth +
        barGroupWidth / 2;
      xAxisLines.push(
        <line
          key={i}
          x1={xPos}
          y1={height - 30 - props.fixedMarginBottom}
          x2={xPos}
          y2={height - 20 - props.fixedMarginBottom}
          stroke="#bbb"
          strokeWidth={0.5}
          strokeDasharray={props.backgroundLinesType == "dashed" ? "5 5" : "0"}
        />
      );

      xAxisLabels.push(
        <text
          key={i}
          x={xPos}
          y={height - 10 - props.fixedMarginBottom}
          textAnchor="middle"
          fontSize="10"
          color="black"
        >
          {dataKey}
        </text>
      );
    }

    return (
      <>
        {xAxisLines}
        {xAxisLabels}
      </>
    );
  };

  return (
    <div
      id="target"
      ref={targetRef}
      style={{
        width: "100%",
        height: "100%",
      }}
    >
      <div
        style={{
          width: "100%",
          height: "100%",
          overflowY: "auto",
          overflowX: "auto",
        }}
      >
        <div
          style={{
            padding: "0px",
            backgroundColor: props.backgroundColor,
            margin: "0 auto",
            width: props.fixedChartWidth
              ? props.fixedChartWidth + "px"
              : "100%",
            height: props.fixedChartHeight
              ? props.fixedChartHeight + "px"
              : "100%",
          }}
        >
          <svg height={height} width={width}>
            {renderYAxis()}
            {renderXAxis()}

            {renderBars(props.data)}
          </svg>
        </div>
      </div>
    </div>
  );
};

export default BarChart;
