import { useParams } from "react-router-dom";
import Chart from "./LineChart";
import { getChartFromFirebase } from "../firebase";
import { useEffect, useState } from "react";

const Vizu: React.FC = () => {
  const { vizuId } = useParams();
  const [data, setData] = useState([0]);
  const [colors, setColors] = useState(["#222"]);
  const [lineThickness, setLineThickness] = useState(1);
  const [backgroundLinesType, setBackgroundLinesType] = useState("solid");
  const [chartLoaded, setChartLoaded] = useState(false);

  useEffect(() => {
    if (vizuId !== undefined) {
      getChartFromFirebase(vizuId).then((chartData) => {
        console.log("chartData " + chartData);
        setData(chartData!.data);
        setColors(chartData!.colors);
        setLineThickness(chartData!.lineThickness);
        setBackgroundLinesType(chartData!.backgroundLinesType);
        setChartLoaded(true);
      });
    }
  }, [vizuId]);

  return (
    <div style={{ height: "100%" }}>
      {chartLoaded ? (
        <Chart
          data={data}
          colors={colors}
          lineThickness={lineThickness}
          fixedMarginTop={20}
          fixedMarginLeft={60}
          fixedMarginBottom={60}
          backgroundLinesType={backgroundLinesType}
          fixedMarginRight={30}
          backgroundColor={"#222"}
          backgroundLineColor="333"
          yAxisFontSize={33}
          xAxisFontSize={12}
        />
      ) : (
        <div
          style={{
            margin: 0,
            position: "absolute",
            top: "50%",
            left: 0,
            right: 0,
          }}
        >
          <center>Vizuforge chart loading</center>
        </div>
      )}
    </div>
  );
};

export default Vizu;
