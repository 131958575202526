// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { addDoc, collection, doc, getDoc, getDocs, getFirestore, query, updateDoc } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyC0TEshHulys-O6PBdpkij4Jle4awnGUZw",
  authDomain: "vizuforge.firebaseapp.com",
  projectId: "vizuforge",
  storageBucket: "vizuforge.appspot.com",
  messagingSenderId: "1078746125432",
  appId: "1:1078746125432:web:24d55042d5eccf042a498d"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Cloud Firestore and get a reference to the service
const db = getFirestore(app);


export const addChartToFirebase = async (data: number[], colors: string[], lineThickness : number, backgroundLinesType:string) => {
    try {
        const docRef = await addDoc(collection(db, "charts"), {
          type: "line",
          data: data,
          colors: colors,
          lineThickness: lineThickness,
          backgroundLinesType: backgroundLinesType
        });
        console.log("Document written with ID: ", docRef.id);

        return docRef.id;
      } catch (e) {
        console.error("Error adding document: ", e);
      }
      return null;
}

export const updateChartInFirebase = async (chartId: string, data: number[], colors: string[], lineThickness : number, backgroundLinesType:string) => {

  const chartRef = doc(db, "charts", chartId);

// Set the "capital" field of the city 'DC'
    await updateDoc(chartRef, {
      type: "line",
      data: data,
      colors: colors,
      lineThickness: lineThickness,
      backgroundLinesType : backgroundLinesType
      }
    );

}

export const getChartFromFirebase = async (chartId: string) => {

  const chartRef = doc(db, "charts", chartId);

  const docSnap = await getDoc(chartRef);

  if (docSnap.exists()) {
    console.log("Document data:", docSnap.data());
    const data = docSnap.data();

    return data;

  } else {
    // docSnap.data() will be undefined in this case
    console.log("No such document!");
  }

  return null;

}

export const getChartsFromFirebase = async ()=>{
  const q = query(collection(db, "charts"));

  let charts: string[]= [];

  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    console.log(doc.id, " => ", doc.data());
    charts.push(doc.id);
  });

  return charts;
}
