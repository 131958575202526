import { createHashRouter } from "react-router-dom";
import App from "./App";
import Vizu from "./components/Vizu";
import Editor from "./components/Editor";

const router = createHashRouter([
  {
    path: "/",
    element: (
      <>
        <App />
      </>
    ),
  },
  {
    path: "/privacy-policy",
    element: (
      <>
        <div style={{ padding: "2em" }}>
          <h1>Privacy policy</h1>
          Information we collect: We collect information about the use of the
          web page for statistics and analysis purposes. The information that
          may be collected is: - URL and titles of visited pages and clicked
          links on visited pages. - Refferer - User agent - Screen resolution -
          Languages - X/Y coordinates of mouse events - Anonymized IP address We
          do not use any cookies to collect this information.
        </div>
      </>
    ),
  },

  {
    path: "/vizu/:vizuId",
    element: <Vizu />,
  },
  {
    path: "/editor/:vizuId",
    element: (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <div style={{ width: "100%", height: "100%" }}>
          <Editor />
        </div>
      </div>
    ),
  },
]);

export default router;
