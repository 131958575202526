import React, { useState } from "react";

interface DataEditorProps {
  initialData: string[][];
  onDataUpdate: (updatedData: any[]) => void;
}

const DataEditor: React.FC<DataEditorProps> = ({
  initialData,
  onDataUpdate,
}) => {
  const [data, setData] = useState<string[][]>(initialData);

  const handleCellEdit = (
    rowIndex: number,
    colIndex: number,
    newValue: string
  ) => {
    const updatedData = [...data];
    updatedData[rowIndex][colIndex] = newValue;
    setData(updatedData);
  };

  const addRow = () => {
    const updatedData = [...data];
    // Ensure that the no of columns of the new row is equal to the other
    const newRow = [] as string[];
    data[0].map((column, columnIndex) => {
      newRow.push("1");
    });
    updatedData.push(newRow);
    setData(updatedData);
  };

  const addColumn = () => {
    const updatedData = [...data];
    // Ensure that the no of columns of the new row is equal to the other
    data.map((row, rowIndex) => {
      updatedData[rowIndex].push("23");
    });

    setData(updatedData);
  };

  const convertDataToChartDataObject = () => {
    const returnArray = [] as any[];
    data.map((row, rowIndex) => {
      if (rowIndex > 0) {
        const rowData = [] as any[];
        const cellsInRow = data[rowIndex];
        cellsInRow.map((cell, cellIndex) => {
          if (cellIndex > 0) {
            const cellObject = { [data[0][cellIndex]]: parseFloat(cell) };
            if (cell !== ".") rowData.push(cellObject);
          }
        });

        returnArray.push({ [data[rowIndex][0]]: rowData });
      }
    });

    onDataUpdate(returnArray); // Call the callback with updated data
  };

  return (
    <div>
      <table>
        <tbody>
          {data.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {row.map((cell, colIndex) => (
                <td key={colIndex}>
                  <input
                    type="text"
                    value={cell}
                    onChange={(e) =>
                      handleCellEdit(rowIndex, colIndex, e.target.value)
                    }
                  />
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <button onClick={addRow}>Add row</button>
      <button onClick={addColumn}>Add column</button>

      <button onClick={convertDataToChartDataObject}>
        Save and update chart
      </button>
    </div>
  );
};

export default DataEditor;
