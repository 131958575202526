import { useEffect, useState } from "react";

interface ColorSelectorProps {
  initialData: string;
  onDataUpdate: (updatedData: string) => void;
}

const ColorSelector: React.FC<ColorSelectorProps> = ({
  initialData,
  onDataUpdate,
}) => {
  const [color, setColor] = useState<string>(initialData);

  const handleColorChange = (newValue: string) => {
    setColor(newValue);
  };

  useEffect(() => {
    setColor(initialData);
  }, [initialData]);

  return (
    <>
      <div>
        <div>
          <input
            type="text"
            value={color}
            onChange={(event) => handleColorChange(event.target.value)}
          />
        </div>
        <button
          onClick={() => {
            onDataUpdate(color);
          }}
        >
          Update background color
        </button>
      </div>
    </>
  );
};

export default ColorSelector;
