import { useEffect, useState } from "react";

interface ColorListProps {
  initialData: string[];
  onDataUpdate: (updatedData: string[]) => void;
}

const ColorList: React.FC<ColorListProps> = ({ initialData, onDataUpdate }) => {
  const [colors, setColors] = useState<string[]>(initialData);

  const handleColorChange = (index: number, newValue: string) => {
    const newColors = [...colors];
    newColors[index] = newValue;
    setColors(newColors);
  };

  useEffect(() => {
    setColors(initialData);
  }, [initialData]);

  return (
    <>
      <div>
        {colors.map((color, index) => (
          <div key={index}>
            <input
              type="text"
              value={color}
              onChange={(event) => handleColorChange(index, event.target.value)}
            />
          </div>
        ))}
        <button
          onClick={() => {
            setColors([...colors, "#222222"]);
          }}
        >
          Add color
        </button>
        <button
          onClick={() => {
            onDataUpdate(colors);
          }}
        >
          Update colors
        </button>
      </div>
    </>
  );
};

export default ColorList;
